import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const RedirectToWhatsApp = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const whatsappNumber = searchParams.get("wapp_number");
        const message = searchParams.get("message") || "Hola, quiero más información";

        if (whatsappNumber) {
            const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
            window.location.href = whatsappURL;
        } else {
            console.error("Número de WhatsApp no especificado");
            navigate("/");
        }

        const timeout = setTimeout(() => navigate("/"), 3000);
        return () => clearTimeout(timeout);
    }, [navigate, searchParams]);

    return <p>Redirigiendo a WhatsApp...</p>;
};

export default RedirectToWhatsApp;